(function ($) {
  "use strict"

  function readUrl(input) {
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      reader.onload = e => {
        let imgData = e.target.result;
        let imgName = input.files[0].name;
        input.setAttribute("data-title", imgName);
        console.log(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  $("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#pageWrappper").toggleClass("toggled");
    });

})(jQuery);


